@import '../../../../assets/variables';

.table__header {
  box-shadow: 0 3px 0px -1px rgb(213, 207, 190);
  font-size: $font-size-m;
  line-height: 1.1;
  transform: scale(1);

  .icon-sort-arrows {
    color: $very-light-pink;
    font-size: 24px;

    &:hover {
      color: $darker-grey;
    }

    &.color__darker-grey {
      color: $darker-grey;
    }
  }

  &__cell {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: space-between;
    padding: 10px;

    &--checked {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 20px;
    }

    &__icon {
      width: 24px;
      height: 23px;
    }
  }
}
