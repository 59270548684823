@import '../../../../assets/variables';

.modal--order-notes {
  padding: 30px 50px 25px;

  .text {
    font-size: 13px;
    margin: 10px 0;

    &--note-type {
      font-size: 15px;
      margin: 15px 0 10px 0;
      font-style: italic;
      font-family: $font-family-bold;
    }

  }

  .title {
    font-size: 25px;
    display: inline;
    margin-right: 10px;
  }

  .btn-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0 45px 65px;

    .btn {
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}

.notes-list {
  margin-bottom: 20px;
}
