@import '../../../assets/variables';

.main {
  &__header {
    display: flex;
    flex: 100%;
    height: 68px;
  }

  &__nav {
    align-items: center;
    background-color: $secondary-color;
    color: $white;
    display: flex;
    flex: 1 auto;
    padding: 12px 28px;
    font-size: $font-size-l;
    justify-content: flex-end;

    .avatar {
      width: 41px;
      height: 41px;
      margin: 0;
    }
  }

  &__logo {
    align-items: center;
    background-color: $primary-color;
    color: $black;
    display: flex;
    font-size: $font-size-l;
    text-transform: uppercase;
    justify-content: center;
    padding: 13px 0;
    width: 280px;
  }

  &__logo img {
    height: 75px;
  }

  &__svg {
    margin-right: 100px;

    svg {
      defs {
        display: none;
      }

      &:hover {
        cursor: pointer;
        defs {
          display: block;
        }
        g {
          filter: url(#a);
        }
      }
    }
  }

  &__user-text {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
}

.stock_dot {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
