@import '../../../../assets/variables';

.group-add-page {
  .content-header {
    justify-content: space-between;

    &__menu {
      display: flex;
      margin: 0;

      .btn {
        text-transform: uppercase;

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }

  .content-section {
    margin-bottom: 11px;
    padding-bottom: 25px;

    .header {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
    }

    .form {
      padding: 40px 60px 35px 0;

      &__row {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &__footer {
        display: flex;
        font-family: $font-family-bold;
        font-size: $font-size-l;
        line-height: $font-lh-s;
        justify-content: flex-end;
        padding-top: 15px;
        text-transform: uppercase;

        &__confirm {
          color: $third-color;
          cursor: pointer;
          font-family: $font-family-bold;
          line-height: $font-lh-s;

          &:hover {
            color: $tan;
          }

          &--disabled {
            color: $darker-grey;
            cursor: default;
            font-family: $font-family-bold;
          }
        }
      }
    }

    &--details {
      &--filled {
        .header {
          padding: 0;
        }

        .form {
          padding-bottom: 15px;
          max-width: 905px;

          &__row {
            height: auto;
          }
        }
      }
    }

    &--location-group {
      .form-list {
        max-height: 320px;
        overflow: auto;
        margin-top: 20px;
        padding-left: 15px;

        &__footer {
          &__control {
            display: flex;
            justify-content: center;

            &__item {
              align-items: center;
              display: flex;
              color: $third-color;
              cursor: pointer;
              font-size: $font-size-l;
              justify-content: center;
              padding: 5px 45px;

              .icon {
                margin-right: 11px;
              }

              &:first-child {
                border-right: 1px solid $third-color;
              }

              &:hover,
              &:hover .icon {
                color: $tan;
              }
            }
          }
        }
      }

      .form {
        border: 1px solid $black-two;
        border-radius: 4px;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-right: 13px;
        margin-bottom: 20px;
        padding: 30px 45px 0;

        & > .form__row {
          flex: 1 100%;

          &:first-child {
            margin-bottom: 38px;

            .title {
              width: auto;
            }
          }
        }

        &--filled {
          padding-bottom: 15px;

          .form {
            &__row {
              height: auto;

              .title {
                width: auto;
                white-space: nowrap;
              }
            }

            &__main-column {
              align-items: center;
              margin-right: 250px;
              width: 150px;

              &:first-child {
                .form {
                  &__row {
                    &:first-child {
                      align-self: flex-start;
                    }

                    &:last-child {
                      align-self: flex-end;
                    }
                  }
                }
              }

              &:nth-child(2) {
                align-items: stretch;
                margin-top: auto;

                .form {
                  &__row {
                    flex: 100%;

                    .text,
                    .title {
                      flex: 1 50%;
                    }
                  }
                }
              }
            }
          }
        }

        &--dashed {
          border-style: dashed;
        }

        &__row {
          margin-bottom: 15px;
        }

        .switch {
          margin-left: 122px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .group-add-page {
    .content-section {
      &--location-group {
        .form {
          &__main-column {
            margin-right: 20px;
          }

          &__footer {
            padding-top: 12px;
          }
        }
      }
    }
  }
}
