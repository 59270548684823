@import '../../../../assets/variables';

.modal--info {
  justify-content: space-between;
  height: 230px;
  padding: 30px 50px 20px;

  &__close-icon {
    background-color: $primary-color;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0 rgba(138, 137, 134, 0.97);
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 43.7px;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 43.7px;

    &:hover {
      background-color: $tan;
    }

    &:before,
    &:after {
      content: '';
      width: 2px;
      height: 16px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__text {
    align-items: center;
    display: flex;
    flex: 1;
    font-family: $font-family-light;
  }
}
