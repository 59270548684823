@import '../../../../assets/variables';

.form-wrapper {
  align-items: center;
  background-color: $white;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  font-family: $font-family-medium;
  font-size: $font-size-l--two;
  padding: 13px 40px 30px;
  max-height: 318px;
  max-width: 530px;
  width: 70vw;

  &__row {
    align-items: center;
    display: flex;
    padding: 20px 0;
    position: relative;
    width: 100%;

    .svg {
      margin-right: 10px;
      width: 35px;

      &--eye {
        fill: $very-light-pink-two;
        width: 40px;
      }
    }

    &:not(:first-child) {
      border-top: 1px solid $pale;
    }

    &--success {
      .svg--eye {
        fill: $black;
      }
    }
  }

  &__input {
    color: $greyish-brown;
    font-family: $font-family-medium;
    font-size: $font-size-l--two;
    flex: 1;
    outline: none;
    text-transform: uppercase;

    &--error {
      color: $tomato;
    }
  }

  &__error {
    align-items: center;
    display: flex;
    font-size: 0;
    height: 25px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .text {
      color: $tomato;
      font-size: $font-size-l;
    }

    .svg {
      width: 25px;
    }
  }

  .btn {
    text-transform: uppercase;
    font-family: $font-family-medium;
    font-size: $font-size-l--two;
    padding: 20px 160px;
  }
}
