@import '../../../assets/variables';

.expandable-block {
  .filters-list {
    margin-bottom: 20px;
  }
}

.expandable-block__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .btn {
    margin-left: 19px;
    padding: 13px 36px;
    text-transform: uppercase;
    width: auto;

    &--secondary {
      margin-left: 44px;
      text-transform: none;
    }
  }

  .sorting {
    margin-left: auto;
  }

  .btn,
  .sorting {
    display: flex;
    align-items: center;

    &__text {
      margin-right: 20px;
      font-weight: $font-weight-bold;
    }
  }

  .title {
    align-items: center;
    display: flex;
    color: $brownish-grey;
    font-size: $font-size-xl;
    text-transform: uppercase;

    svg {
      margin-right: 10px;
    }
  }

  .filter-btn {
    margin-left: 34px;
    margin-right: auto;

    &:after {
      content: '';

      display: inline-block;
      height: 0;
      width: 0;

      margin-left: 7px;

      border-bottom: none;
      border-top: 6.93px solid #645c49;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &--active {
      text-decoration: underline;
    }
  }
}

.filter-list {
  display: flex;
  list-style-type: none;
}

.default-cursor {
  cursor: default;
}
