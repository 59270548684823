@import '../../../../assets/variables';

$check-height: 48px;
$check-width: 19px;

.modal--success {
  align-items: center;
  justify-content: space-between;
  height: 285px;
  padding: 65px 50px 55px;

  .check-mark {
    background-color: $primary-color;
    border-radius: 50%;
    height: 104px;
    position: relative;
    width: 104px;

    &.draw:after {
      animation-duration: 1000ms;
      animation-timing-function: ease;
      animation-name: checkmark;
      transform: scaleX(-1) rotate(135deg);
    }

    &:after {
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: 3px solid $white;
      border-top: 3px solid $white;
      content: '';
      left: 28px;
      top: 57px;
      position: absolute;
    }
  }

  .check-mark {
    @keyframes checkmark {
      0% {
        height: 0;
        width: 0;
        opacity: 1;
      }
      20% {
        height: 0;
        width: $check-width;
        opacity: 1;
      }
      40% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
      }
      100% {
        height: $check-height;
        width: $check-width;
        opacity: 1;
      }
    }
  }
}
