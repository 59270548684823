@import '../../assets/variables';

.form {
  display: flex;
  font-size: $font-size-m;
  justify-content: space-between;
  padding: 40px 71px 50px 13px;

  &__main-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    .column-title {
      color: $black;
      font-family: $font-family-bold;
      font-size: $font-size-m;
      line-height: $font-lh-m;
      text-align: left;
      margin-right: 22px;
      width: 120px;
      margin-bottom: 15px;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    height: 36px;
    margin-bottom: 40px;
    position: relative;

    &--measure {
      &:after {
        content: 'IN';
        display: block;
        position: absolute;
        left: calc(100% + 8px);
        text-transform: uppercase;
        top: 50%;
        transform: translateY(-51%);
      }
    }

    &--weight {
      &:after {
        content: 'LBS';
        display: block;
        position: absolute;
        left: calc(100% + 8px);
        text-transform: uppercase;
        top: 50%;
        transform: translateY(-51%);
      }
    }

    &--auto-margin {
      margin-bottom: auto;
    }

    .title {
      color: $black;
      font-family: $font-family-bold;
      font-size: $font-size-m;
      line-height: $font-lh-m;
      text-align: right;
      margin-right: 22px;
      width: 120px;

      &--required {
        position: relative;

        &:after {
          color: red;
          content: '*';
          display: inline-block;
          position: absolute;
          right: -6px;
          top: -7px;
        }
      }
    }
  }

  .modal {
    &__trigger {
      cursor: pointer;
      color: $black;
      font-size: $font-size-s;
      position: absolute;
      left: calc(100% + 18px);
      text-decoration: underline;
      top: 10px;
      white-space: nowrap;
    }
  }
}
