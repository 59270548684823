@import '../../../../assets/variables';

* {
  box-sizing: border-box;
}

.page-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100% !important;
  background-color: #f7f6f5;
  position: relative;

  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 530px;
  }

  .login__logo {
    width: 94px;
    height: 72px;
    margin-bottom: 40px;
  }

  .login__title {
    font-size: 50px;
    font-weight: $font-weight-normal;
    color: $black;
    line-height: $font-lh-l;
    margin-bottom: 35px;
  }

  .login__text {
    font-size: $font-size-xxl;
    color: $black;
    margin-bottom: 45px;
  }

  .login__form {
    width: 100%;
    margin-bottom: 30px;
    background-color: transparent;
    border-radius: 0;
    padding: 0;

    .btn {
      width: 100%;
      height: auto;
      padding: 30px 160px;
      line-height: 1;
    }
  }

  .form-wrapper__section {
    width: 100%;
    padding: 22px 40px;
    margin-bottom: 20px;
    background: $white;
    border-radius: 24px;
    box-shadow: 0 3px 8px 0 rgba(217, 217, 217, 0.7);
  }

  .form-wrapper__row {
    margin-bottom: 0;
    height: auto;

    &:not(:first-child) {
      border-top: 1px solid #ebebeb;
    }
  }

  .form-wrapper__input {
    border: none;
    box-shadow: none;
    text-align: center;

    &::-webkit-input-placeholder {
      color: #cecece;
      text-align: center;
    }

    &::-moz-placeholder {
      color: #cecece;
      text-align: center;
    }

    &:-ms-input-placeholder {
      color: #cecece;
      text-align: center;
    }

    &:-moz-placeholder {
      color: #cecece;
      text-align: center;
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 88px;
    top: 58px;
    width: 115px;
    height: 115px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;

    &:active,
    &:focus,
    &:hover {
      background-color: #ede6d8;
    }
  }

  .page {
    &__title {
      color: $white;
      font-family: $font-family-bold;
      font-size: $font-size-xxxl;
      position: relative;
      white-space: nowrap;

      .svg {
        position: absolute;
        left: -50%;
        top: 50%;
        transform: translateY(-50%);
        width: 54px;
      }
    }
  }

  .carousel {
    overflow: hidden;
    position: relative;
    margin-bottom: 33px;
    width: 100%;

    &__item {
      align-items: center;
      display: flex;
      flex-direction: column;
      left: 50%;
      opacity: 1;
      position: absolute;
      transition: 1s ease;
      transform: translateX(-50%);
      z-index: 1;

      &--pending {
        opacity: 0;
        left: 150%;
        transition: none;
        z-index: 0;
      }

      &--hidden {
        opacity: 0;
        left: -100%;
        z-index: 0;
      }
    }

    &--title {
      margin-bottom: 50px;

      .carousel__item {
        transition-duration: 0.5s;
      }
    }
  }

  .form {
    &--check-user {
      margin-bottom: 75px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    min-height: 45px;
    width: 100%;

    .btn {
      background: transparent;
      box-shadow: none;
      color: #443f39;
      font-family: $font-family-medium;
      font-weight: 600;
      width: 180px;

      .svg {
        margin: 0 0 4px 8px;
        width: 30px;
        height: 30px;
        padding: 2px 0 0 1px;
      }
    }
  }

  @media (max-width: 768px) {
    .logo {
      margin: auto;
    }

    .page {
      &__title {
        font-size: $font-size-xxxxl;
      }
    }

    .carousel {
      &--title {
        margin-bottom: 57px;
      }
    }

    .form {
      .btn {
        padding: 30px 160px;
      }

      &__row {
        padding: 30px;
      }
    }
  }

  @media (max-width: 680px) {
    justify-content: space-between;

    .logo {
      margin: 10px auto;
      width: 50px;
    }

    .page {
      &__title {
        font-size: $font-size-l;

        .svg {
          width: 30px;
        }
      }
    }

    .carousel {
      margin-bottom: 23px;
    }

    .form {
      border-radius: 20px;
      margin: 0;
      padding: 10px 10px 15px;
      width: 65vw;

      &__row {
        padding: 10px 0;
      }

      &__error {
        .svg {
          margin-right: 5px;
          width: 20px;

          &--eye {
            width: 30px;
          }
        }

        .text {
          font-size: $font-size-s;
        }
      }

      .btn {
        font-size: 0.6em;
        padding: 10px 50px;
      }
    }

    .footer {
      margin-bottom: 10px;

      .btn {
        font-style: $font-size-s;
      }
    }
  }

  @media (max-width: 640px) and (orientation: portrait) {
    justify-content: space-between;

    .logo {
      margin: auto;
      width: 100px;
    }

    .page {
      &__title {
        font-size: $font-size-xl;
        margin-top: auto;
      }
    }

    .carousel {
      &--title {
        margin-bottom: 30px;
      }
    }

    .form {
      margin-bottom: auto;
      padding-bottom: 15px;
      width: 90vw;

      &__row {
        padding: 15px 0;
      }

      .btn {
        font-size: $font-size-m;
        padding: 15px 60px;
      }
    }

    .footer {
      margin-bottom: 50px;
    }
  }
}

.modal--login-edit {
  border-radius: 24px;
  padding-top: 109px;
  width: 533px;

  .title {
    align-self: center;
  }

  .btn {
    font-family: $font-family-medium;
    text-transform: uppercase;
  }

  @media (max-width: 680px) {
    padding: 30px;
    top: 40%;
    width: 70%;

    .title {
      font-size: $font-size-xl;
    }

    .btn {
      margin-top: 30px;
      padding: 15px 80px;

      &--close {
        bottom: -70px;
      }
    }
  }

  @media (max-width: 680px) and (orientation: portrait) {
    padding: 30px;
    top: 50%;
    width: 100%;

    .btn {
      padding: 20px 80px;
      margin-top: 50px;

      &--close {
        bottom: -93px;
      }
    }
  }
}
