@import '../../../assets/variables';

.LinearProgressbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 130px;
  position: relative;

  &__text {
    position: absolute;
    top: 100%;
    left: 0;
    color: $greyish-brown;
    font-size: $font-size-s;
  }

  &__scale {
    width: 100%;
    background-color: #ede6d8;
    border-radius: 6px;
    flex: 1;
    height: 6px;
    overflow: hidden;
  }

  &__bar {
    background-color: $yellow-orange;
    height: 100%;
  }

  &--empty {
    .linearProgressbar {
      &__text {
        color: $grey;
      }

      &__scale {
        background-color: $third-color;
      }
    }
  }

  &--column {
    flex-direction: column;
  }
}
