@import '../../../assets/variables';

.table {
  border-collapse: collapse;
  width: 100%;

  th,
  .table__data {
    border: 1px solid $very-light-brown-67;
  }

  .table__row__cell--checkbox {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .checkbox {
    height: 18px;
    width: 20px;
  }
}
