@import '../../../assets/variables';

.list {
  .cell {
    width: 25%;
    box-sizing: border-box;
    display: flex;
    font-size: $font-size-m;
    padding: 15px 10px;

    &:last-child {
      width: 90px;
      max-width: 90px;
    }
  }

  &__header {
    display: flex;
    border-bottom: 1px solid $very-light-pink;

    .cell {
      font-family: $font-family-bold;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    border-bottom: 1px solid $very-light-pink;
  }

  &--empty {
    display: flex;
    justify-content: center;

    .text {
      color: $brown-grey-two;
      font-size: $font-size-m;
      font-family: $font-family-bold;
    }
  }
}
