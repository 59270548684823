.page-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  @media (max-width: 1280px) {
    zoom: 0.85;
  }

  @media (max-width: 1024px) {
    zoom: 0.7;
  }

  @media (max-width: 768px) {
    zoom: 0.6;
  }
}

.test-server {
  border: 10px solid red;
}

.test-server-banner {
  width: 100%;
  background-color: red;
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
