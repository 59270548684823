@import '../../assets/variables';

.tasks {
  &__title {
    display: inline-block;
    margin-bottom: 34px;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    color: $black;
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      font-size: 11px;
      color: #131111;
      border-radius: 50%;
      border: solid 1px #343b41;
    }

    .text-wrapper {
      display: flex;
      align-items: center;
      min-width: 150px;
      padding: 0 5px;

      .text {
        margin: 0 16px 0 0;
      }

      .LinearProgressbar {
        width: 160px;
        max-width: none;
        margin-left: 10px;
      }
    }
  }
}
