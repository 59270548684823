@import '../../../assets/variables';

.range {
  background-color: $white;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  color: $greyish-brown;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .text {
      flex: 1 100%;
      font-size: $font-size-l;
      text-align: center;
      margin: 15px;
    }

    .count {
      flex: 1 100%;
      font-size: $font-size-s;
      margin: 6px 0 24px;
      text-align: center;
    }

    .icon--close {
      position: absolute;
      top: 15px;
      right: 15px;

      &:before,
      &:after {
        background: $greyish-brown;
      }
    }
  }

  &__field {
    border: none;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
    padding: 0 15px;

    .range__item {
      cursor: pointer;
      height: 33px;
      margin-bottom: 6px;
      padding: 0 4px;
      width: 40px;

      .text {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        font-size: $font-size-m;
        justify-content: center;
        height: 100%;
      }

      &:hover {
        .text {
          background-color: #e1d6b4;
          border-radius: 50%;
        }
      }

      &--intermediate {
        background-color: $light-tan;
        color: $greyish-brown;
        padding: 0 5px;
        margin: 0 0 6px;
        border: 1px solid $light-tan;

        &:hover {
          .text {
            background-color: $light-tan;
            border: none;
          }
        }
      }

      &--selected {
        .text {
          background-color: $copper;
          border-color: $copper;
          border-radius: 50%;
          color: white;
        }

        &:hover {
          .text {
            background-color: $copper;
          }
        }
      }

      &--first {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        border-right-color: $light-tan;
        padding-left: 0;
      }

      &--last {
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        border-left-color: $light-tan;
        padding-right: 0;
      }
    }
  }

  .footer {
    .select-all {
      display: flex;
      margin: 15px 0 10px;
      padding-left: 25px;

      .text {
        margin-left: 28px;
      }
    }

    .btn {
      margin: 11px;
      margin-left: auto;
    }
  }

  .btn-menu {
    display: flex;
    justify-content: flex-end;

    .btn {
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
