@import '../../../../assets/variables';

.content-header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  &__section {
    margin-right: 90px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    color: $greyish-brown-two;
    font-family: inherit;
    font-size: $font-size-xxl;
    text-transform: uppercase;
  }

  &__subtitle {
    color: $brown-grey-two;
    font-family: inherit;
    font-size: $font-size-m;
    line-height: $font-lh-l;
  }
}
