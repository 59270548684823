@import '../../../../assets/variables';

.modal--confirm {
  min-height: 300px;

  .text {
    align-items: flex-end;
    display: flex;
    flex: 1;
    font-family: $font-family-primary;
    justify-content: center;
    letter-spacing: -0.33px;
    padding: 30px 45px 55px;
  }

  .btn-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0 45px 65px;

    .btn {
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}

.modal.modal--login {
  padding: 90px 38px 20px;
  width: 610px;

  .title {
    align-self: center;
    margin-bottom: 45px;
    font-weight: normal;
    font-family: $font-family-primary;
  }

  .svg {
    position: absolute;
    bottom: -94px;
    left: 50%;
    transform: translate(-50%);
  }

  .text {
    align-items: center;
    display: flex;
    flex: 1;
    font-family: $font-family-light;
    letter-spacing: -0.33px;
    justify-content: center;
  }

  .btn-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .btn {
      font-size: 20px;
      text-transform: uppercase;
      width: 100%;
      height: 80px;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}
