@import '../../../../../assets/variables';

.popup--staff-detail {
  width: 50px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;

  .header {
    align-items: center;
    background-color: $black-lighter;
    color: $white;
    display: flex;
    justify-content: center;
    position: relative;

    .title {
      text-align: center;
      font-size: 24px;
    }

    .btn--empty {
      position: absolute;
      right: 61px;
    }
  }

  .desc {
    align-items: flex-end;
    display: flex;
    margin: 70px 45px 95px;

    .avatar {
      height: 120px;
      margin-right: 40px;
      width: 120px;

      .icon__img {
        width: 100%;
      }
    }

    &__info {
      flex: 1;

      .title {
        font-size: $font-size-xxl;
        font-family: $font-family-bold;
        margin-bottom: 25px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;

        .badge {
          background-color: $beige;
          border-radius: 16px;
          border: none;
          color: $white;
          font-size: $font-size-m;
          justify-content: center;
          height: auto;
          margin-right: 12px;
          margin-bottom: 12px;
          padding: 5px 30px;
          max-width: 115px;
          white-space: nowrap;

          &--active {
            background-color: $yellow-orange;
          }
        }
      }
    }
  }
}

.ReactModalPortal {
  .staff-detail {
    position: relative;
    width: 100%;
    max-width: 1180px;
    padding: 30px;
    box-shadow: 3px 3px 4px 0 rgba(138, 137, 134, 0.27);
    background: #f7f6f5;
    border-radius: 8px;
    margin: auto;

    .desc {
      display: flex;
      align-items: flex-end;
      margin-bottom: 80px;

      .title {
        font-size: 30px;
        color: #262626;
        border: none;
        font-weight: $font-weight-bold;
        padding: 0;
        margin-bottom: 26px;
      }
    }

    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 120px;
      height: 120px;
      margin-right: 40px;
    }

    .close {
      position: absolute;
      bottom: -94px;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
