@import '../../../../assets/variables';

.about-page {
  .about-content-fresh {
    background-color: #3d8f3d;
    color: white;
  }

  .about-content-stale {
    background-color: #ad1f1f;
    color: white;
  }

  .header {
    margin: 0;
  }

  .freshness-icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .update-app {
    margin-top: 20px;
    width: 200px;
    background-color: #3d8f3d;
  }

  .test-exception {
    color: #3d8f3d;
    background-color: #3d8f3d;
  }
}
