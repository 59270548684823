@import '../../../../assets/variables';

.content-section {
  background-color: $white;
  box-shadow: 0 1px 3px 0 rgba(192, 183, 159, 0.27);
  padding: 30px 29px;
  padding-top: 10px;

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px; 
    padding: 15px 20px 20px 0;

    &__title {
      color: $darker-grey;
      font-family: $font-family-light;
      font-size: $font-size-m;
      line-height: $font-lh-m;

      &--bold {
        font-family: $font-family-bold;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .btn:not(:last-child) {
      margin-right: 25px;
    }
  }

  &--empty {
    padding: 24px 18px;

    .header {
      margin-bottom: 0;
      padding: 0;
    }
  }

  &__message {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    padding: 20px 20px 20px 30px;

    font-family: $font-family-light;
    font-size: $font-size-m;
    line-height: $font-lh-m;
 
    span {
      margin-right: 5px;
    }

    &--error {
      @extend .content-section__message;
      background-color: $light-red;
    }

    &--success {
      @extend .content-section__message;
      background-color: $light-green;
    }
  }
}
