@import '../../../../assets/variables';

.container-list-page {
    .content-header {
        &__menu {
            align-items: center;
            display: flex;

            .content-header__select {
                align-items: center;
                display: flex;
                margin-right: 30px;

                .select {
                    width: 226px;
                }

                &:first-child {
                    margin-right: 90px;
                }
            }
        }
    }

    .content-section {
        padding-top: 20px;

        .header {
            margin-bottom: 30px;

            &__title {
                color: $darker-grey;
                font-size: $font-size-m;
                line-height: $font-lh-m;
                text-transform: uppercase;
            }
        }

        .icon {
            &-wrapper {
                align-items: center;
                color: $third-color;
                cursor: pointer;
                display: flex;
                margin-bottom: 15px;
            }

            &__text {
                text-decoration: underline;
            }

            &--print {
                font-size: 31px;
                margin-right: 4px;
            }
        }

        .filter {
            align-items: baseline;
            display: flex;
            margin: 0 0 25px 10px;

            .switch {
                margin-bottom: -20px;

                &__tab {
                    margin-bottom: 20px;

                    &__text {
                        color: $black;
                    }
                }

                &__expander {
                    margin-left: 15px;
                }
            }

            .text {
                color: $black;
                font-size: $font-size-m;
                margin-right: 16px;
                width: 60px;
            }
        }

        .table {
            margin-top: 40px;

            &__data {

                &:first-child {
                    width: 79px;

                    .table__row__cell {
                        padding: 10px 5px 10px 15px;
                    }
                }

                &:last-child {
                    width: 84px;

                    .table__row__cell {
                        padding: 10px 20px;
                    }

                }
            }
            
            &__row__cell {
                line-height: $font-lh-xl;
                padding: 9px
            }
        }

        &--empty {
            .header {
                margin: 0;
            }
        }
    }
}
