@import '../../../../assets/variables';

.table__row {
  &:nth-of-type(2n + 1) {
    background-color: #fdfdfd;
  }

  &__cell {
    color: $black;
    display: flex;
    font-size: $font-size-m;
    line-height: $font-lh-m;
    padding: 18px 10px;

    .icon--square {
      border-radius: 1.5px;
      display: inline-block;
      height: 10px;
      margin-right: 6px;
      width: 10px;
    }

    .icon-wrapper:not(:last-child) {
      margin-right: 10px;
    }
  }
}
