@import 'assets/variables';
.modal--stale {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;

  &__exclamation {
    margin: 0px;
    margin-bottom: -40px;
    font-size: 150px;
  }

  &__text {
    margin: 20px;
  }

  &__title {
    font-size: 24px;
    color: #cc0000;
    margin-bottom: 10px;
  }

  &__version {
    margin: 10px;
    font-size: 14px;
    color: #666666;
  }
}
