@import '../../../assets/variables';

.react-datepicker {
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
  width: 310px;

  &__current-month {
    background-color: #f1f0ec;
    color: $greyish-brown;
    font-size: 20px;
    line-height: 1;
    padding: 17px 0;
  }

  &__day {
    align-items: center;
    color: $greyish-brown;
    display: inline-flex;
    justify-content: center;
    font-size: $font-size-m;
    height: 33px;
    margin: 2px 3.5px;
    width: 33px;

    &:hover {
      background-color: $egg-shell;
    }

    &--today {
      background-color: $white;
      font-weight: normal;
      position: relative;

      &:after {
        content: '.';
        color: #ab1401;
        display: inline-block;
        font-size: 30px;
        line-height: 0;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, calc(-50% + 2px));
      }

      &:hover {
        background-color: $egg-shell;
      }
    }

    &--selected {
      background-color: $copper;
      color: $white;

      &:hover {
        background-color: $copper;
        color: $white;
      }

      &.react-datepicker__day {
        &--today {
          &:after {
            display: none;
          }
        }
      }
    }

    &--outside-month {
      visibility: hidden;
    }

    &-name {
      color: #a69f8f;
      font-size: 12px;
      height: 33px;
      margin: 1px 3.5px;
      width: 33px;
    }

    &-names {
      margin: 37px 9px 0;

      &:after {
        border-bottom: 1px solid $egg-shell;
        bottom: 0;
        content: '';
        display: inline-block;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: calc(100% - 49px);
      }
    }
  }

  &__header {
    background-color: $white;
    border-bottom: none;
    padding-top: 0;

    &-day {
      font-size: $font-size-m;
      color: $greyish-brown;
      position: absolute;
      top: 68px;
      left: 50%;
      transform: translateX(-50%);

      .text {
        font-weight: 600;
      }
    }
  }

  &__month {
    margin: 9px;

    &-container {
      width: 100%;
    }
  }

  &__navigation {
    border: none;
    border-right: 3.5px solid $greyish-brown;
    border-bottom: 3.5px solid $greyish-brown;
    display: inline-block;
    height: 13px;
    top: 21px;
    width: 13px;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: $greyish-brown;
    }

    &--next {
      right: 32px;
      transform: rotate(-45deg);
    }

    &--previous {
      left: 32px;
      transform: rotate(-225deg);
    }
  }

  &__triangle {
    display: none;
  }
}
