.page-content {
  background-color: rgba(242, 241, 240, 0.66);
  flex: 1;
  height: calc(100% - 70px);
  padding: 25px 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    /*  top: 45px;
    right: 0;
    left: 120px;*/
  }
}
