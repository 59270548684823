@import '../../../../assets/variables';

.modal--default {
  align-items: center;
  background-color: $white;
  border: solid 0.5px $egg-shell;
  border-radius: 8px;
  box-shadow: 3px 3px 4px 0 rgba(138, 137, 134, 0.27);
  color: $black;
  display: flex;
  flex-direction: column;
  font-family: $font-family-primary;
  height: auto;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 484px;

  .title {
    align-self: flex-start;
    color: $black;
    font-size: $font-size-xxl;
    font-family: $font-family-bold;
  }

  .text {
    color: $black;
    font-size: $font-size-m;
  }

  .btn {
    padding: 25px 110px;
  }

  &__body {
    max-height: 250px;
  }
}

.btn--close {
  bottom: -93px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
