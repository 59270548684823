@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('/fonts/Roboto-Regular.woff') format('woff');
  src: local('Roboto-Regular'),
    url('/fonts/Roboto-Regular.ttf') format('truetype');
  src: local('Roboto-Regular'),
    url('/fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.ttf') format('truetype');
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.woff') format('woff');
  src: local('Roboto-Light'), url('/fonts/Roboto-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url('/fonts/Roboto-Bold.ttf') format('truetype');
  src: local('Roboto-Bold'), url('/fonts/Roboto-Bold.woff') format('woff');
}

//COLORS
$yellow-orange: #f3b000;
$dark-sand: #b6994e;
$copper: #a68225;
$tan: #dbba63;

$black: #000000;
$black-two: #373737;
$black-lighter: #4b4b4b;
$dark-brown: #261b00;
$chocolate-brown: #3a2a00;
$greyish-brown: #645c49;
$brownish-grey: #646464;

$greyish-brown-two: #4b4b4b;
$brown-grey-two: #74736e;

$white: #ffffff;
$egg-shell: #e5e1d5;
$light-tan: #e5dcc0;
$grey: #808080;
$brown-grey: #868277;
$darker-grey: #929292;

$very-light-brown-67: rgba(192, 183, 159, 0.67);
$very-light-brown-27: rgba(192, 183, 159, 0.27);
$beige: #cbc3b1;
$very-light-pink: #cccccc;
$very-light-pink-two: #e5e5e5;
$merkury: #e9e9e9;

$pale: #ede6d8;
$tomato: #ea4733;

$light-green: #81e7aa;
$light-red: #e78c81;

//BASIC
$primary-color: $yellow-orange;
$secondary-color: $greyish-brown;
$third-color: $copper;

//TYPOGRAPHY
$primary-text-color: $black;
$secondary-text-color: $white;
$title-text-color: $black;

$font-family-primary: 'Roboto-Regular', sans-serif;
$font-family-light: 'Roboto-Light', sans-serif;
$font-family-medium: 'Roboto-Medium', sans-serif;
$font-family-bold: 'Roboto-Bold', sans-serif;

$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-l--two: 20px;
$font-size-xl: 18px;
$font-size-xxl: 22px;
$font-size-xxxl: 36px;
$font-size-xxxxl: 48px;

$font-lh-s: 0.64;
$font-lh-m: 0.71;
$font-lh-l: 1;
$font-lh-xl: 1.2;

$font-weight-light: 300;
$font-weight-normal: normal;
$font-weight-bold: bold;
