@import '../../../assets/variables';

.checkbox {
  display: inline;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    border: 1px solid $black;
    border-radius: 1.5px;
    box-shadow: inset 0 1px 3px 0 rgba(129, 129, 129, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: $white;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 0;
      width: 5px;
      height: 10px;
      border: solid $black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__input:checked ~ &__checkmark:after {
    display: block;
  }
}
