@import '../../assets/variables';

.radio:checked,
.radio:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio:checked + .radio__label,
.radio:not(:checked) + .radio__label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #666;
}
.radio:checked + .radio__label:before,
.radio:not(:checked) + .radio__label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  border: 1px solid $beige;
  border-radius: 100%;
  background: $white;
}
.radio:checked + .radio__label:after,
.radio:not(:checked) + .radio__label:after {
  content: '';
  width: 4px;
  height: 4px;
  background: $black;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio:not(:checked) + .radio__label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio:checked + .radio__label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
