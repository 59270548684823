@import '../../../../assets/variables';

.modal--inv-adjustment {
  &.modal--default {
    height: 100%;
    max-height: 483px;
    max-width: 747px;
    width: 100%;
  }

  .title {
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    margin: 20px 0 15px 55px;
  }

  .row {
    align-items: center;
    display: flex;
    margin-bottom: 2px;
    width: 100%;
  }

  .row__column {
    flex: 1;
  }

  .sub-title {
    flex: 0.56;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $font-lh-m;
    margin-right: 15px;
    text-align: end;
  }

  .text-input {
    max-width: 40px;
    padding-left: 0;
    text-align: center;
  }

  .select {
    width: 227px;
  }

  .btn-menu {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding: 25px;
    width: 100%;

    .btn {
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 125px;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
