@import '../../../../assets/variables';

.modal--warning {
  align-items: center;
  height: 348px;
  padding: 96px 40px 25px;
  width: 622px;
  font-weight: $font-weight-bold;

  &__title {
    margin-bottom: 19px;
  }

  .btn {
    align-self: flex-end;
  }
}
