@import '../../../assets/variables';

.sidebar {
  background-color: $secondary-color;
  color: $white;
  display: flex;
  flex-direction: column;
  font-size: $font-size-m;
  height: 100%;
  justify-content: flex-start;
  padding-top: 100px;
  position: relative;
  transition: width 0.3s ease-in-out;
  width: 80px;

  .toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 62px;
    justify-content: center;
    position: absolute;
    right: -20px;
    top: 40px;
    width: 62px;
    z-index: 1000;

    .svg-hover:active {
      use {
        fill: $tan;
        stroke: $tan;
      }

      path {
        fill: $white;
      }
    }
  }

  .version-info {
    color: #ccc;
  }

  .nav {
    display: flex;
    flex-direction: column;

    &__item {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex: 100%;
      flex-wrap: wrap;
      position: relative;

      .text {
        .badge--point {
          position: absolute;
          width: 9px;
          height: 9px;
          margin-left: 5px;
          border-radius: 50%;
          background-color: #ea4733;
        }
      }

      &:hover,
      &--selected {
        .title {
          background-color: rgba(166, 159, 143, 0.77);
        }

        .text {
          font-family: $font-family-bold;
        }
      }

      .list {
        background-color: rgba(0, 0, 0, 0.17);
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.29);
        display: none;
        flex-direction: column;
        justify-content: center;
        padding: 18px 0;
        width: 100%;

        &__item {
          align-items: center;
          cursor: pointer;
          display: flex;
          height: 55px;
          list-style-type: none;
          padding: 0 30px;
          position: relative;

          .badge--text {
            flex: 1 0 auto;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            background-color: #ea4733;
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.5s ease, visibility 0.5s ease;
            visibility: hidden;
          }

          &--selected {
            .list {
              &__text {
                font-family: $font-family-bold;
                text-decoration: underline;

                &--short {
                  color: $greyish-brown;
                  background-color: #eee8d3;
                  font-family: $font-family-bold;
                }
              }
            }
          }
        }

        &__text {
          flex: 1 100%;
          opacity: 0;
          overflow: hidden;
          transition: opacity 0.5s ease, visibility 0.5s ease;
          visibility: hidden;

          &--short {
            align-items: center;
            border: 1px solid #eee8d3;
            border-radius: 50%;
            color: $white;
            display: inline-flex;
            height: 18px;
            flex: 0 0 18px;
            font-size: $font-size-m;
            justify-content: center;
            margin-right: 20px;
          }
        }
      }

      .label {
        display: none;
      }

      .icon {
        &__wrapper {
          display: flex;
          justify-content: center;
          flex: 0 0 30px;
          margin-right: 20px;
        }

        &--empty {
          width: 22px;
          height: 22px;
          border-radius: 5px;
          background-color: $white;
        }
      }

      .title:hover .label,
      .list__item:hover .label {
        animation-name: navLabelAnimation;
        animation-duration: 3000ms;
        animation-fill-mode: forwards;
        background-color: $light-tan;
        border-radius: 2px;
        color: $black;
        display: flex;
        font-size: 16px;
        padding: 8px 14px;
        position: absolute;
        left: calc(100% + 8px);
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        z-index: 1000;

        @keyframes navLabelAnimation {
          0% {
            opacity: 0;
          }
          40% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          90% {
            opacity: 1;
          }
          100% {
            display: none;
            opacity: 0;
          }
        }
      }

      .title {
        align-items: center;
        display: flex;
        font-size: $font-size-l;
        height: 55px;
        padding: 0 5px 0 25px;
        position: relative;
        width: 100%;
      }

      .text {
        display: inline-block;
        flex: 1;
        opacity: 0;
        transition: opacity 0.5s ease, visibility 0.5s ease;
        visibility: hidden;
        white-space: nowrap;
        width: 0;
      }

      &--active {
        .list {
          display: flex;
        }
      }
    }

    .divider {
      align-self: center;
      border: 0.5px solid $very-light-brown-27;
      box-sizing: border-box;
      height: 1px;
      margin: 35px 0 30px;
      width: calc(100% - 30px);
    }
  }

  &--open {
    width: 280px;

    .nav {
      &__item {
        .list {
          &__item {
            @media (max-width: 1024px) {
              margin-bottom: 12px;
            }

            .badge--text {
              opacity: 1;
              visibility: visible;
            }
          }

          &__item--selected {
            .list__text--short {
              color: transparent;
            }
          }

          &__text {
            display: inline-block;
            opacity: 1;
            visibility: visible;

            &--short {
              color: transparent;
              flex-basis: 18px;
              height: 18px;
              width: 18px;
              text-align: center;
              line-height: 18px;
              margin-right: 26px;
            }
          }
        }

        .text {
          opacity: 1;
          visibility: visible;
        }

        .title:hover .label,
        .list__item:hover .label {
          display: none;
        }
      }
    }

    .version-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      padding: 0 0 90px 28px;
      opacity: 1;
      visibility: visible;
      transition: opacity 1s ease-in-out;

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
      }

      &__date {
        display: none;
        font-size: 12px;
      }

      &__square {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background-color: $yellow-orange;
      }

      &__text {
        display: flex;
        cursor: pointer;
        line-height: $font-lh-l;

        &:hover {
          .version-info__date {
            display: block;
          }

          @media (min-width: 767px) and (max-width: 1024px) {
            flex-direction: column;
          }
        }

        strong {
          font-family: $font-family-bold;
        }
      }
    }
  }
}

.svg {
  width: 27px;
  height: 29px;
  margin-top: 8px;

  &--container {
    margin-top: 15px;
  }

  &--order {
    margin-top: 11px;
  }

  &--gear {
    margin-top: 15px;
    padding-left: 8px;
  }
}
