@import '../../../assets/variables';

.btn {
  align-items: center;
  background-color: $primary-color;
  border: none;
  border-radius: 2px;
  color: $white;
  cursor: pointer;
  display: flex;
  font-family: $font-family-bold;
  font-size: $font-size-l;
  justify-content: center;
  outline: none;
  text-decoration: none;
  height: 44px;
  width: auto;
  padding: 20px;

  &--clicked {
    opacity: 0.9 !important;
  }

  &:hover {
    opacity: 0.97;
  }

  &:disabled {
    background: $very-light-pink;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
}

.btn--secondary {
  background-color: $white;
  border: 1px solid $copper;
  border-radius: 2px;
  color: $copper;
  font-size: $font-size-m;
  font-family: $font-family-bold;
  line-height: $font-lh-m;
  height: 44px;
  width: auto;
  padding: 20px;

  &:hover {
    background-color: $copper;
    color: $white;
  }
}
