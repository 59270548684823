@import '../../../../assets/variables';

.list-page {
  .content-header {
    &__menu {
      align-items: center;
      display: flex;

      .select {
        margin-right: 30px;
        width: 226px;
      }
    }
  }

  .content-section {
    .header {
      &__title {
        color: $darker-grey;
        font-size: $font-size-m;
        line-height: $font-lh-m;
        text-transform: uppercase;
      }

      &__menu {
        display: flex;
        height: 24px;
      }

      &__link {
        align-items: center;
        display: flex;
        color: $third-color;
        font-size: $font-size-l;
        text-decoration: none;

        &:hover {
          color: $tan;
        }

        &:first-child {
          border-right: 1px solid $third-color;
          padding-right: 29px;
        }

        &:last-child {
          text-align: right;
          padding-left: 29px;
        }
      }
    }

    .icon {
      &-wrapper {
        align-items: center;
        color: $third-color;
        cursor: pointer;
        display: flex;

        &--print {
          display: inline-flex;
          margin-bottom: 15px;

          &:hover {
            .icon,
            .icon__text {
              color: $tan;
            }
          }
        }
      }

      &__text {
        text-decoration: underline;
      }

      &-print {
        font-size: 31px;
        margin-right: 4px;
      }
    }

    .filter-section {
      align-items: center;
      display: flex;
      flex-wrap: wrap;

      margin-bottom: 35px;
     
      .select {
        margin-right: 20px;
      }
    }

    .table {
      &__row__cell {
        &--control {
          padding: 0;

          .icon {
            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media print {
  @page {
    margin: 0;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }

  .location-page__print {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .print {
      width: 9in;
      height: 2.4in;

      &__inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
      }

      &__code-number {
        margin: 0 0 0 20px;
        display: block;
      }

      &__loc-name {
        font-size: 50px;
        letter-spacing: 3px;
      }

      &__text-input {
        border: 1px solid black;
      }
    }
  }
}
