@import '../../../assets/variables';

.drag-drop {
  &__table-header {
    position: relative;
    background: #fff;
    display: flex;
    padding: 0 30px;
    box-shadow: 0 2px 2px 0 rgba(202, 193, 172, 0.25);
    font-weight: $font-weight-bold;
  }

  &__table {
    width: 100%;
    overflow: hidden;
    margin-bottom: 60px;
    box-shadow: 0 1px 3px 0 rgba(192, 183, 159, 0.27);
    background: $white;

    .table-cell {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 150px;
      min-height: 60px;
      padding: 10px;

      img {
        max-height: 30px;
        max-width: 100%;
      }

      &:last-child {
        max-width: 50px;
      }

      &:nth-child(7) {
        max-width: 180px;
      }

      &:nth-child(8) {
        max-width: none;
      }
    }
  }

  &__wrapper {
    min-height: 30px;
  }

  &__row-header {
    display: flex;
    padding: 0 30px;

    .text--new {
      font-weight: $font-weight-bold;
      color: #ea4733;
      text-transform: uppercase;
    }
  }

  &__row {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 13px;
      top: 50%;

      display: none;
      width: 16px;
      height: 10px;
      background: url('../../../assets/svg/burger.svg');

      transform: translateY(-50%);
    }

    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 30px;
      right: 30px;

      height: 1px;

      background: #f7f7f7;
      box-shadow: 0 1px 0 0 rgba(192, 183, 159, 0.27);
    }

    &:active {
      background: $merkury;
      box-shadow: 0 2px 2px 0 rgba(61, 61, 61, 0.5);

      &:before {
        display: block;
      }

      &:after {
        content: none;
      }
    }

    .list {
      list-style-type: none;
      padding: 20px 40px;

      &__item {
        &--title {
          font-weight: $font-weight-bold;
          margin-bottom: 10px;
        }
      }
    }
  }
}
