@import '../../../../assets/variables';

.container-details-page {
  flex-direction: column;
  height: calc(100% - 68px);
  padding-bottom: 20px;

  .content-header {
    flex-wrap: wrap;
    max-height: 139px;
    width: 100%;

    &__title {
      margin-right: auto;
    }

    .btn-menu {
      align-items: center;
      display: flex;

      .btn {
        text-transform: uppercase;

        &:first-child {
          margin-right: 25px;
        }
      }
    }

    .status-bar {
      margin-top: 20px;
      width: 33%;
      @media (min-width: 1200px) and (max-width: 1800px) {
        width: 48%;
      }
      @media (width: 768px) {
        width: 44%;
      }

      &--wrapper {
        width: 100%;
      }

      svg {
        margin-left: 5px;
      }

      &--text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        svg {
          margin-top: 5px;
        }
        @media (max-width: 1024px) {
          .text {
            font-size: 11px;
          }
        }
      }

      .icon {
        align-items: center;
        cursor: default;
        flex-direction: column;
        position: relative;

        .svg--wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          position: relative;
          z-index: 2;
        }

        &:not(:last-child):after {
          display: inline-block;
          content: '';
          cursor: default;
          border: 0.8px solid $greyish-brown;
          position: absolute;
          z-index: 1;
          top: 27px;
          left: 50%;
          @media (min-width: 1200px) and (max-width: 1800px) {
            width: calc(50% + 80px);
          }
          width: calc(50% + 60px);
        }

        .text {
          color: $greyish-brown;
          font-family: $font-family-bold;
          line-height: $font-lh-l;
          margin-top: 5px;
        }

        &--disabled {
          cursor: pointer;

          .text {
            color: $brown-grey-two;
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-flow: column wrap;
    height: calc(100% - 159px);
    min-height: 660px;

    @media (max-width: 1024px) {
      margin-top: 50px;
    }

    .content-section {
      box-sizing: border-box;
      padding: 10px 20px;
      margin-bottom: 35px;
      width: 100%;

      .header {
        margin-bottom: 0;

        &__title {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        &__edit {
          display: none;
        }

        &__edit--active {
          display: block;
          color: #a68225;
          cursor: pointer;
          font-size: 16px;
        }

        .text {
          &--bold {
            font-weight: bold;
            font-family: $font-family-primary;
          }
        }
      }

      .form {
        flex-wrap: wrap;
        padding: 40px 70px 0 0;

        &__main-column {
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          &:first-child {
            .form {
              &__row {
                margin-bottom: 35px;
                @media (max-width: 1024px) {
                  font-size: 14px;
                  &:first-child {
                    width: 340px;
                    .title {
                      margin-right: 60px;
                    }
                  }
                }
              }
            }
          }
        }

        &__row {
          margin-left: 5px;
          margin-bottom: 10px;
          min-width: 285px;
          white-space: nowrap;

          .title {
            margin-right: 16px;
            width: 105px;
          }

          .react-datepicker-wrapper {
            cursor: pointer;
            margin-left: 20px;
          }
        }
      }
    }

    .footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .text {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin-top: 20px;

        svg {
          margin-left: 12px;
        }

        &--active {
          color: $copper;

          &:hover {
            color: $tan;

            path {
              fill: $tan;
            }
          }
        }

        &--disabled {
          color: $darker-grey;
          cursor: default;

          svg {
            path {
              fill: $darker-grey;
            }
          }
        }
      }
    }

    &--minimised {
      .content-section {
        display: inline-block;
        margin-right: 12px;
        max-height: 470px;
        width: 456px;

        .form {
          flex-direction: column;

          &__main-column {
            flex-direction: column;

            &:first-child {
              .form {
                &__row {
                  margin-bottom: 25px;
                }
              }
            }

            &:last-child {
              .form {
                &__row {
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &__row {
            margin-bottom: 25px;
          }
        }
      }

      .product-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 760px;
        margin: 0;
        min-width: 400px;
        overflow: auto;
        width: calc(100% - 468px);

        .list {
          &__header {
            .cell {
              padding-top: 40px;
            }
          }

          .cell {
            &:first-child {
              padding-left: 0;
            }
          }
        }

        &--empty {
          height: 226px;

          .list {
            align-items: center;
            flex: 1;
          }
        }

        .svg--checkmark {
          margin: 4px;
        }
      }

      .footer {
        width: 456px;
      }
    }
  }

  .btn-group {
    margin-left: auto;
  }
}

.qa-hold .header {
  margin-bottom: 0;
}

.content-section > * .row {
  align-items: center;
  height: 100%;
}

.qa-hold .content-section .container-info.row {
  align-items: center;
  margin-left: 50px;
}

.qa-hold .container-info .column {
  width: 50%;
}

.qa-hold .content-section {
  margin-bottom: 11px;
}

.qa-hold .content-section > .row {
  align-items: flex-start;
  margin-top: 45px;
  margin-left: 26px;
}

.qa-hold .column {
  flex: 2 0;

  &:nth-child(3) {
    flex: 3;
  }

  &:nth-child(4) {
    flex: 4;
  }

  &:not(:last-child) {
    margin-top: 10px;
  }
}

.qa-hold .column .row {
  white-space: nowrap;
}

.qa-hold .text--bold {
  margin-right: 15px;
}

.qa-hold .react-datepicker-wrapper {
  margin-left: 20px;
}

.qa-hold .svg--remove {
  cursor: pointer;
  height: 13px;
  margin-right: 10px;
  margin-top: 12px;
  min-width: 13px;
}

.qa-hold__lot {
  margin-bottom: 15px;
  min-width: 305px;
}

.qa-hold__lot .svg {
  cursor: pointer;
  margin-left: 10px;
}
