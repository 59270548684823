@import '../../../assets/variables.scss';

.pallet-icon-image-wrapper {
  position: relative;
  margin-right: 15px;
  max-height: min-content;
  min-width: 33px;
}

.pallet-text-span {
  position: relative;
  top: 4px;
  left: auto;
}

.pallet-icon-p {
  text-align: center;
  margin-right: 30px;
  border-width: 0px;
  min-width: 30px;
}

.pallet-icon-svg {
  max-width: 30px;
  max-height: 10px;
}
