@import '../../assets/variables';

.modal {
  background-color: $white;
  border: solid 0.5px $egg-shell;
  border-radius: 8px;
  box-shadow: 3px 3px 4px 0 rgba(138, 137, 134, 0.27);
  box-sizing: border-box;
  color: $black;
  display: flex;
  flex-direction: column;
  font-size: $font-size-xl;
  position: relative;

  &__text {
    font-weight: $font-weight-light;
  }
}
