@import '../../../assets/variables';

.toggle {
  align-items: center;
  display: flex;

  &__text {
    margin-right: 6px;
  }
}
