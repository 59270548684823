.badge {
  display: inline-block;
  background-color: #cbc3b1;
  border-radius: 14px;
  color: #ffffff;
  font-size: 15px;
  justify-content: center;
  height: auto;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 5px 15px;
  max-width: 130px;

  &--active {
    background-color: #efc200;
  }
}
