@import '../../../../assets/variables';

.orders-removed-page {
  .expandable-block {
    &__header {
      color: $brownish-grey;
      font-size: 18px;
      font-family: $font-family-light;
    }
  }
  .list {
    &__header,
    &__row {
      .cell {
        padding: 25px 18px 25px 0;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          flex: 0.5;
          max-width: unset;
        }
        &:nth-child(6) {
          flex: 0.7;
        }
        &:nth-child(7) {
          flex: 0.4;
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          .case-count-span {
            min-width: 65px;
          }
          svg:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
