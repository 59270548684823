@import '../../../../assets/variables';

$check-height: 48px;
$check-width: 19px;

.modal--pending {
  align-items: center;
  font-size: $font-size-xl;
  padding: 50px;
  position: relative;

  .outer-circle {
    animation-duration: 0.4s;
    animation-name: circle-spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 139px;
    margin-bottom: 20px;
    width: 139px;

    @keyframes circle-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  .inner-round {
    background-color: #f4d383;
    border-radius: 50%;
    height: 95px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 119px;
    transform: translate(-50%, -50%);
    width: 96px;

    .inner-round__curtain {
      animation: round-spin 2.4s infinite;
      animation-timing-function: steps(5, ease);
      background-color: $white;
      height: 95px;
      width: 96px;

      @keyframes round-spin {
        0% {
          height: 100%;
        }
        35% {
          height: 80%;
        }
        50% {
          height: 50%;
        }

        75% {
          height: 25%;
        }
        100% {
          height: 0;
        }
      }
    }
  }

  .text {
    position: relative;
    left: 7px;
  }
}
