@import '../../../assets/variables';

.switch {
  align-items: stretch;
  border: 1px solid $beige;
  border-radius: 4.5px;
  box-sizing: border-box;
  display: inline-flex;
  overflow: hidden;

  &__tab {
    align-items: center;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 3px;
    box-sizing: border-box;
    color: $brown-grey;
    cursor: pointer;
    font-size: $font-size-m;
    display: flex;
    height: 40px;
    justify-content: center;
    transition: all 0.3s ease;
    user-select: none;
    width: 52.5px;

    &:first-child {
      border-width: 0 1px 0 0;
    }

    &:last-child {
      border-width: 0 0 0 1px;
    }

    &__text {
      font-family: $font-family-bold;
      white-space: nowrap;
    }

    &__radio {
      display: none;
    }

    &--active {
      background-color: $primary-color;
      border: 1px solid $beige;
      color: $chocolate-brown;
    }
  }

  &__expander {
    align-self: center;
    background-color: $white;
    border: none;
    color: $copper;
    cursor: pointer;
    font-size: $font-size-m;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  &--multiple {
    flex-wrap: wrap;

    .switch {
      &__tab {
        border: 0px solid $beige;
        border-width: 0 1px 0 0;
        border-radius: 0;
        width: 74px;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    &.switch--secondary {
      align-items: center;
      align-content: space-between;
      border: none;
      border-radius: 0;
      flex: 1;

      .switch {
        &__tab {
          border: none;
          display: inline-flex;
          padding: 7px 24px;
          height: auto;
          width: auto;

          &__text {
            font-family: $font-family-primary;
          }

          &--active {
            background-color: $light-tan;
            border-radius: 1.5px;
          }
        }
      }
    }
  }
}
