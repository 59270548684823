@import '../../../../assets/variables';

.modal--products-list {
  padding: 40px 20px 18px 45px;
  width: 840px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 7px 5px 5px 0 rgba(138, 137, 134, 0.27);
  border-bottom: solid 9px $white;
  min-height: 225px;
  position: absolute;
  transform: translate(0, -50%);
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    width: 680px;
  }
  ::-webkit-scrollbar {
    width: 20px;
  }
  &__header {
    display: flex;
    width: 96%;
    justify-content: flex-start;
    padding-bottom: 25px;
    border-bottom: solid 0.5px $very-light-brown-67;
    .order-info {
      &:last-child {
        margin-left: 80px;
      }
      &--title {
        font-family: $font-family-light;
      }
      &--text {
        font-family: $font-family-bold;
      }
    }
  }
  .list {
    padding-right: 10px;
    max-height: 455px;
    overflow: scroll;
    &__header {
      padding-top: 5px;
      border-bottom: 1.3px solid $very-light-brown-67;
    }
    &__row {
      border-bottom: 0.5px solid $very-light-brown-67;
      &:last-child {
        border: none;
      }
    }
    &__header,
    &__row {
      .cell {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          flex: 1.5;
        }
      }
    }
  }
  .btn-wrapper {
    width: 96%;
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 0;
    }
  }
}
