@import '../../../../../assets/variables';

.orders-shipping-page {
  .filters-list {
    display: flex;
    margin-bottom: 20px;
    .select {
      margin-right: 20px;
      &__control {
        background-color: white;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .list {
    &__header {
      .cell span {
        font-family: $font-family-bold;
      }
    }

    .cell {
      padding: 20px 18px 20px 0;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0.4;
      }

      &:last-child {
        flex: 0.4;
        max-width: unset;
      }

      &:nth-child(6) {
        display: flex;
        align-items: center;
      }

      &--shipping-lane {
        min-width: 100px;
        margin-left: -130px;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin-left: -80px;
        }
      }
    }
  }

  .btn {
    margin-left: 19px;
    padding: 13px 36px;
    text-transform: uppercase;
    width: auto;
    display: flex;
    align-items: center;
    &__text {
      margin-right: 20px;
      font-weight: $font-weight-bold;
    }
  }

  .filter-btn {
    margin-right: auto;

    &:after {
      content: '';

      display: inline-block;
      height: 0;
      width: 0;

      margin-left: 7px;

      border-right: none;
      border-left: 6.93px solid #645c49;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &--active {
      text-decoration: underline;
      &:after {
        border-bottom: none;
        border-top: 6.93px solid #645c49;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
  }

  .icon-check {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
}
