@import '../../../assets/variables';

.text-area {
  border: solid 0.5px $beige;
  border-radius: 1.5px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  font-size: $font-size-m;
  height: 80px;
  padding: 10px 0 0 10px;
  width: 250px;
  

  &::placeholder {
    color: $black;
    font-size: $font-size-m;
  }

  &--invalid {
    border: solid 1px #ea2124;

    &::placeholder {
      color: #ea2124;
    }
  }

  &:disabled {
    background-color: $white;
    border: solid 0.5px $beige;

    &::placeholder {
      color: $darker-grey;
    }
  }
}
