@import '../../../../assets/variables';

.orders-page {
  .content-header .btn {
    padding: 13px 36px;
    margin-left: auto;
    width: auto;
  }

  .drag-drop__table-header {
    .table-cell {
      &:nth-child(11),
      &:nth-child(12) {
        max-width: 50px;
      }
    }
  }

  .drag-drop__row-header .table-cell {
    &:nth-child(11),
    &:nth-child(12) {
      max-width: 50px;
    }
  }

  .expandable-block__header {
    .title {
      font-size: 15px;
    }
    .btn--secondary {
      line-height: 1;
      margin-right: 15px;
    }
  }
}

.expandable-block__header .btn {
  height: auto;
}

.switch__tab {
  background: transparent;
}

.text--assign {
  color: $copper;
  font-size: 16px;
}

.text--bold {
  font-weight: $font-weight-bold;
}

.delete-icon, .notes-icon {
  font-size: 24px;
  cursor: pointer;
}
