.page--error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-wrapper {
    width: 77%;
    height: 90%;
    position: absolute;
    top: 70px;
    left: 280px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 15px;
  }
}
