@import '../../../assets/variables';

.inventory-page {
  .table__data {
    padding: 5px 0;
  }

  .load-more-btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table {
    th:last-child {
      width: 175px;
    }

    @media (max-width: 1280px) {
      th:last-child {
        width: 155px;
      }

      &__header__cell,
      &__row__cell {
        font-size: $font-size-s;
      }

      &__row__cell {
        height: auto;
        padding: 15px;
      }
    }
  }

  .checkbox {
    width: 17px;
    height: 17px;

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__text {
      margin-left: 13px;
      font-size: 14px;
      font-weight: normal;
      color: $black;
    }

    &__input {
      width: 17px;
      height: 17px;
    }
  }

  .table--pallets {
    table-layout: fixed;

    .table__data,
    th {
      width: calc(100% / 8);
    }

    th:first-child,
    th:last-child {
      .table__header__cell {
        min-width: auto;
      }
    }

    .table__data:first-child {
      min-width: auto;
    }
  }

  .table__row__cell {
    align-items: center;
    padding: 0 10px;
    line-height: 1.2;
    span {
      width: 100%;
    }
    .icon--square {
      width: 10px;
    }
  }

  .icon-edit {
    margin-left: auto;
  }
}

.restock-task-detail-page {
  height: 100%;

  .btn {
    text-transform: uppercase;
  }

  .btn-group {
    margin-left: auto;
  }

  .title {
    align-items: center;
    display: flex;
    color: #646464;
    font-size: 18px;
    text-transform: uppercase;
    padding: 0px;
  }

  .table {
    margin-bottom: 60px;
    box-shadow: 0 1px 3px 0 rgba(192, 183, 159, 0.27);
  }

  .table__header {
    position: relative;
    background: #fff;
    border-bottom: 1px solid rgba(202, 193, 172, 0.25);
    font-weight: bold;

    th:first-child {
      .table__header__cell {
        padding-left: 20px;
      }
    }

    th:last-child {
      .table__header__cell {
        padding-right: 20px;
      }
    }
  }

  .table__header__cell,
  .table__row__cell {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 60px;
    padding: 10px;
    background: #fff;
  }

  .table {
    .table__data,
    th {
      border: none;
    }
  }

  .table__row__cell {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 1px;
      left: 0;
      height: 1px;
      background: #f7f7f7;
      box-shadow: 0 1px 0 0 rgba(192, 183, 159, 0.27);
    }
  }

  .table__data:first-child {
    .table__row__cell {
      padding-left: 20px;
    }

    .table__row__cell:after {
      left: 20px;
    }
  }

  .table__data:last-child {
    .table__row__cell {
      justify-content: flex-end;
      padding-right: 50px;
    }

    .table__row__cell:after {
      left: -20px;
    }
  }

  .filter-btn {
    margin-left: 34px;

    &:after {
      content: '';

      display: inline-block;
      height: 0;
      width: 0;

      margin-left: 7px;

      border-bottom: none;
      border-top: 6.93px solid #645c49;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    &--active {
      text-decoration: underline;
    }
  }

  .filters-list {
    margin-bottom: 25px;
    display: flex;

    .select {
      margin-right: 20px;
      width: 180px;
    }

    &__title {
      margin-right: 25px;
    }
  }

  .switch__tab {
    background: transparent;
  }
}

.inv-filter-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.bottom {
  margin-bottom: 35px;
}

.pallets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
