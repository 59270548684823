@import './variables';
@import './icon-fonts/css/style.css';
@import './components/form';
@import './components/modal';
@import './components/radio-btn';
@import './components/badge';
@import './components/tasks';

* {
  margin: 0;
  padding: 0;
  font-family: $font-family-primary;

  &::-webkit-scrollbar {
    background-color: $white;
    width: 14px;

    &-button {
      display: none;
    }

    &-thumb {
      border: 4px solid $white;
      border-radius: 9px;
      background-color: $egg-shell;
      height: 60%;
      width: 14px;
    }
  }
}

html,
body,
#root {
  height: 100%;
  overflow-y: hidden;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

input {
  -webkit-appearance: none;
}

hr {
  box-sizing: border-box;
  border: 0.5px solid $very-light-brown-27;
  height: 1px;
}

img {
  max-width: 100%;
  height: auto;
}

.svg-hover {
  cursor: pointer;
  &:hover {
    path {
      fill: $tan;
    }
  }
  &__eye:hover {
    cursor: pointer;
    use {
      stroke: $tan;
    }
    g {
      fill: none;
      path {
        fill: $tan;
      }
    }
  }
}

.icon {
  cursor: pointer;
  color: #a68225;

  &:hover {
    color: $tan;
  }

  &-edit,
  &-delete {
    font-size: 40px;
  }

  &-add,
  &-copy {
    font-size: 26px;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.btn-group {
  display: flex;

  .btn {
    margin-left: 25px;
  }
}

.blurred {
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}
