@import '../../../../assets/variables';

.task-assignment {
  .btn-group {
    margin-left: auto;
  }

  .btn {
    text-transform: uppercase;
  }

  .title {
    align-items: center;
    display: flex;
    color: #646464;
    font-size: 18px;
    text-transform: uppercase;
  }

  .content-section {
    display: flex;
    align-items: center;
    padding: 21px 38px;
    margin-bottom: 60px;
  }

  .list__item {
    span {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .list {
    list-style: none;

    &--row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .tab {
    &__list {
      display: flex;
      margin-bottom: 25px;
      border-bottom: solid 2px #f0ece2;
    }

    &__item {
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      max-width: 175px;
      padding: 20px 0;
      cursor: pointer;

      &--active {
        font-weight: $font-weight-bold;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 2px;
          background: #ffb601;
        }
      }
    }

    &__mark {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      font-size: 11px;
      border: 1px solid #000;
      border-radius: 50%;
    }

    &__content {
      padding: 0 20px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px 0 rgba(192, 183, 159, 0.27);

      .title {
        padding: 20px 0 19px;
        font-size: $font-size-m;
        text-transform: none;
        border-bottom: 1px solid $very-light-brown-67;
      }
    }
  }

  .assign-block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .title {
      margin-right: 30px;
    }

    .list {
      display: flex;
    }

    .list__item {
      position: relative;

      &--close {
        position: absolute;
        top: 0;
        right: -5px;
        width: 17px;
        height: 17px;
        margin: 0;
        border-radius: 50%;
        background-color: #343b41;
        color: #ffffff;
        font-size: 14px;
        text-align: center;

        &:before {
          content: '';
          width: 13px;
          height: 1px;
          top: 8px;
          left: 2px;
          transform: rotate(45deg);
          background-color: #ffffff;
          position: absolute;
        }

        &:after {
          content: '';
          width: 13px;
          height: 1px;
          top: 8px;
          left: 2px;
          transform: rotate(-45deg);
          background-color: #ffffff;
          position: absolute;
        }
      }
    }

    .list__item + .list__item {
      margin-left: 20px;
    }
  }
}

.popup--assignment {
  height: calc(100% - 180px);
  margin-top: 180px;

  .header {
    border-top-right-radius: 49px;
    border-top-left-radius: 49px;
    display: flex;
    justify-content: space-between;
    padding: 0 60px;

    .text {
      font-size: $font-size-l;
    }
  }

  .body {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100% - 90px);
    padding: 40px 40px 0;
  }

  .title {
    font-family: $font-family-bold;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px 40px;

    &:nth-child(3) {
      margin: 0px 0 22px;
    }

    .title {
      flex: 0 1 100%;
      font-size: $font-size-xxl;
      margin: 0 35px 16px;
    }

    .text {
      font-size: $font-size-xl;
      margin: 0 32px;
      white-space: nowrap;

      &:nth-child(3) {
        position: relative;

        &:after,
        &:before {
          border: 0 solid $black-two;
          content: '';
          display: inline-block;
          height: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          border-width: 0 0 0 1px;
          right: -32px;
        }

        &:before {
          border-width: 0 1px 0 0;
          left: -32px;
        }
      }
    }

    .switch {
      border: 1px solid $grey;
      border-radius: 27px;
      margin: 0 32px;

      &__tab {
        background-color: transparent;
        color: $black-two;
        width: 147px;
        height: 48px;
        outline: none;

        &--active {
          background-color: $grey;
          border-radius: 50px;
          color: $white;
        }

        &__text {
          font-family: $font-family-primary;
        }
      }
    }

    &:nth-child(3) {
      flex-wrap: nowrap;
      max-width: 78vw;

      .title {
        flex-basis: auto;
        white-space: nowrap;
        align-self: center;
        margin: 0 32px 0 0;
      }
    }

    .avatar {
      margin: 0 25px 0 0;
    }

    .list {
      flex-direction: row;
      max-width: 450px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        position: relative;

        &--close {
          position: absolute;
          top: 0;
          right: 16px;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-color: $black-lighter;
          color: $white;
          font-size: 14px;
          text-align: center;

          &:before {
            content: '';
            height: 13px;
            width: 1px;
            top: 2px;
            transform: rotate(45deg);
            background-color: $white;
            position: absolute;
          }

          &:after {
            content: '';
            height: 13px;
            width: 1px;
            top: 2px;
            transform: rotate(-45deg);
            background-color: $white;
            position: absolute;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    height: calc(100% - 40px);
    margin-top: 40px;

    .row {
      margin-bottom: 20px;
    }
  }
}
