@import 'assets/variables';

.environment-banner {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.environment-name {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
