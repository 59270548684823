@import '../../../assets/variables';

.inventory-page {
  padding: 25px 27px;

  .content-header {
    display: flex;
    margin-bottom: 22px;

    &__section {
      flex: 1;
    }

    &__title {
      font-size: 22px;
      font-weight: 300;
      font-family: $font-family-light;
    }

    &__subtitle {
      font-family: $font-family-light;
      font-size: 14px;
      margin-top: 10px;
    }

    .btn:last-child {
      margin-left: 25px;
    }
  }

  .title {
    font-family: $font-family-light;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: normal;
    margin: 10px 0;
  }

  .list {
    border-radius: 1.5px;
    background: $white;
    margin-bottom: 50px;
    box-shadow: 0 1px 3px 0 rgba(192, 183, 159, 0.27);
    border: solid 0.5px #f7f7f7;

    &__header,
    &__row {
      display: flex;
      align-items: center;
    }

    &__header {
      box-shadow: 0 2px 2px 0 rgba(202, 193, 172, 0.25);
      border: none;
      padding: 0 20px;
    }

    &__row {
      border-bottom: solid 0.5px #f7f7f7;
      border-top: solid 0.5px #f7f7f7;
      margin: 0 20px;

      &:last-child {
        margin-bottom: 20px;
        border-bottom: solid 1.5px #f7f7f7;
      }
    }

    .cell {
      padding: 15px 18px 15px 0;
      width: 100%;
      flex: 0.5;

      &:first-child,
      &:last-child {
        max-width: 160px;
      }

      &:nth-child(4) {
        flex: 1;
      }

      .icon-calendar {
        margin-left: 25px;
      }
    }
  }
}
