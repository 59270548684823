@import '../../../assets/variables';

@mixin triangle-left {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #f7f6f5;
}

@mixin triangle-right {
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #f7f6f5;
}

@mixin triangle-top {
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f7f6f5;
}

.avatar-list {
  display: flex;
  flex-direction: row-reverse;

  .avatar {
    background-size: contain;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;

    &:not(:last-child) {
      margin-left: -15px;
    }
  }

  &__count {
    align-items: center;
    background-color: #e1d3ab;
    border-radius: 50%;
    color: $white;
    display: flex;
    font-family: $font-family-primary;
    font-size: $font-size-s;
    justify-content: flex-end;
    line-height: 0.8;
    height: 29px;
    margin-left: -9px;
    padding-right: 5px;
    width: 29px;
  }
}

.card {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  margin: 10px;
  position: relative;
  width: 95px;

  .badge--circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 33px;
    right: 6px;
    padding: 0;
    color: $white;
    font-size: 10px;
    border-radius: 50%;
    background: #d5c8af;
  }

  .avatar {
    height: 46px;
    margin-bottom: 10px;
    width: 46px;

    .icon--check {
      display: none;
    }

    &--success {
      .CircularProgressbar .CircularProgressbar-path {
        stroke: #417d36;
      }

      .icon--check {
        display: inline-flex;
        position: absolute;
        top: 33px;
        right: 12px;
      }
    }
  }

  .badge {
    margin-bottom: 5px;
  }

  .text {
    color: $black;
    font-size: $font-size-s;
    font-family: $font-family-primary;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 5px;
    text-align: center;

    &.card__load {
      color: #c5b68c;
      font-size: $font-size-s;
      font-family: $font-family-primary;
    }
  }

  .popup {
    position: fixed;
    padding: 20px 30px 20px 30px;
    box-shadow: 3px 3px 12px 0 rgba(77, 76, 74, 0.27);
    background: #f7f6f5;
    border-radius: 8px;
    transform: translateY(-50%);
    z-index: 1000;

    .desc {
      display: flex;
      align-items: flex-start;
      margin-bottom: 44px;

      .title {
        font-size: $font-size-xxl;
        color: #262626;
        border: none;
        font-weight: $font-weight-bold;
        padding: 0;
        margin-bottom: 14px;
      }
    }

    .avatar {
      flex-shrink: 0;
      width: 77px;
      height: 77px;
      margin-right: 24px;
    }

    .triangle {
      display: block;
      width: 20px;
      height: 50px;
      position: absolute;
      transform: translateY(-50%);

      &--left {
        left: -20px;
        top: 50%;

        &:before {
          @include triangle-left;
        }
      }

      &--right {
        right: -20px;
        top: 50%;

        &:before {
          @include triangle-right;
        }
      }

      &--top {
        left: 39%;
        top: 7px;

        &:before {
          @include triangle-top;
        }

        &-right {
          left: 85%;
          top: 7px;

          &:before {
            @include triangle-top;
          }
        }
      }

      &--bottom-left {
        left: -20px;
        top: 89%;

        &:before {
          @include triangle-left;
        }
      }

      &--bottom-right {
        right: -20px;
        top: 89%;

        &:before {
          @include triangle-right;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
      }
    }

    .close {
      display: none;
    }
  }
}

.card-list {
  display: flex;
  justify-content: space-between;

  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: 50px 0 30px;
  }
}

.avatar {
  border-radius: 50%;
  height: 46px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 46px;

  .checkmark {
    background-color: rgba(151, 126, 16, 0.55);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    height: 46px;
    padding: 12px;
    width: 46px;
    transform: translateX(-50%);
  }
}
