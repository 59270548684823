@import '../../../assets/variables.scss';

.select {
  margin-top: 10px;
  margin-right: 20px;

  & &__control {
    border-radius: 1.5px;
    box-shadow: none;
    font-size: 14px;
    overflow: hidden;

    &:hover {
      border-color: $primary-color;

      .select__indicators {
        background-color: $light-tan;
      }
    }

    &.select__control--is-focused {
      border-color: $primary-color;
      box-shadow: 0 0 0 1px #f3b000;

      &:hover {
        border-color: $primary-color;
      }
    }

    &--menu-is-open {
      .select {
        &__indicator {
          border-bottom: 6.93px solid $greyish-brown;
          border-top: none;
        }
      }
    }
  }

  &--invalid .select__control {
    border-color: #ea2124 !important;
    box-shadow: 0 0 0 1px #ea2124 !important;
  }
  
  &--invalid .select__menu {
    border-color: #ea2124 !important;
  }
  
  &--invalid .select__value-container {
    color: #ea2124 !important;
  }
  
  &--invalid .select__single-value {
    color: #ea2124 !important;
  }
  
  &--invalid .select__indicator-separator {
    background-color: #ea2124 !important;
  }
  
  &--invalid .select__option--is-focused {
    background-color: #f5c6cb !important;
  }  

  &__indicators {
    background-color: $egg-shell;
    padding: 0 14px;
  }

  &__indicator {
    border-bottom: none;
    border-top: 6.93px solid $greyish-brown;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    height: 0;
    width: 0;
  }

  &__menu {
    overflow: hidden;
    padding-top: 0;
    padding-right: 2px;

    &-list {
      padding: 2px 4px;
      padding-right: 2px;
    }
  }

  &__option {
    box-sizing: border-box;
    border-radius: 3px;
    margin-top: 2px;
    margin-right: 0;
  }

  &--is-disabled {
    background-color: $very-light-pink-two;
  }
}
