@import '../../../assets/variables';

.tooltip {
  width: 100%;
  cursor: default;
  white-space: nowrap;
}

.carrier-icon-target {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
}

.tooltip-text {
  visibility: hidden;
  background-color: $secondary-color;
  color: $secondary-text-color;
  text-align: center;
  border-radius: 7px;
  padding: 5px;
  z-index: 1;
  height: 100%;
  max-height: 22px;
  white-space: nowrap;
}
.for-total-cases {
  margin-left: 10px;
}

.for-carrier-logo {
  margin-left: 2px;
  position: absolute;
  display: inline-flex;
  align-items: center;
}
.for-picker-icon-bubble {
  margin-top: -8px;
  position: absolute;
  display: inline-flex;
  align-items: center;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.side-text {
  font-size: 70%;
}
