@import '../variables';

.CircularProgressbar {
  height: 56px;
  position: absolute;
  left: 50%;
  top: -5px;
  transform: translateX(-50%) rotate(180deg);
  width: 56px;

  & .CircularProgressbar-path {
    stroke: #c5b68c;
  }

  & .CircularProgressbar-trail {
    stroke: $white;
  }
}
