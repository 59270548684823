.voided {
  color: red;
  font-weight: bold;
}
  
.voided-row{
  filter: blur(2px);
}

.link.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
