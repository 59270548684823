@import '../../../../../assets/variables';

.orders-create-shipment-page {
    .content-section {
        margin-top: 20px;

        .header {
            margin-bottom: 0;
        }
    }

    .info-section {
        margin-bottom: 20px;
    }

    .info-section > * {
        margin: 10px 0;
    }

    .link {
        color: $third-color;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          color: $tan;
        }
      }

}
